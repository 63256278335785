export const databaseTags = [
    // Patient Record
    { label: "Title", value: "title", category: "Patient Record" },
    { label: "Last Name", value: "last_name", category: "Patient Record" },
    { label: "Middle Name", value: "middle_name", category: "Patient Record" },
    { label: "First Name", value: "first_name", category: "Patient Record" },
    { label: "Gender", value: "gender", category: "Patient Record" },
    { label: "Date Of Birth (YYYY-MM-DD)", value: "dob", category: "Patient Record" },
    { label: "Date of Birth: Year", value: "dob_year", category: "Patient Record" },
    { label: "Date of Birth: Month", value: "dob_month", category: "Patient Record" },
    { label: "Date of Birth: Day", value: "dob_day", category: "Patient Record" },
    { label: "Health Card Number", value: "health_card_number", category: "Patient Record" },
    { label: "Health Card Ver", value: "health_card_ver", category: "Patient Record" },
    { label: "Health Card Province", value: "health_card_province", category: "Patient Record" },
    { label: "Cell Phone", value: "cell_phone", category: "Patient Record" },
    { label: "Email", value: "email", category: "Patient Record" },
    { label: "Residential Address", value: "residential_address", category: "Patient Record" },
    { label: "Residential City", value: "residential_city", category: "Patient Record" },
    { label: "Residential Province", value: "residential_province", category: "Patient Record" },
    { label: "Residential Country", value: "residential_country", category: "Patient Record" },
    { label: "Residential Postal", value: "residential_postal", category: "Patient Record" },
    { label: "Residential Address Complete {Address, City, Province Country Postal}", value: "residential_address_complete", category: "Patient Record" },
    { label: "Home Phone", value: "home_phone", category: "Patient Record" },
    { label: "Work Phone & Ext", value: "work_phone_ext", category: "Patient Record" },
    { label: "Family Physician Last Name", value: "family_physician_last_name", category: "Patient Record" },
    { label: "Family Physician First Name", value: "family_physician_first_name", category: "Patient Record" },
    { label: "Family Physician Phone", value: "family_physician_phone", category: "Patient Record" },
    { label: "Family Physician Fax", value: "family_physician_fax", category: "Patient Record" },
    { label: "Family Physician Address", value: "family_physician_address", category: "Patient Record" },
    { label: "Family Physician City", value: "family_physician_city", category: "Patient Record" },
    { label: "Family Physician Province", value: "family_physician_province", category: "Patient Record" },
    { label: "Family Physician Country", value: "family_physician_country", category: "Patient Record" },
    { label: "Family Physician Postal", value: "family_physician_postal", category: "Patient Record" },
    { label: "Family Physician Address Complete {Address, City, Province Country Postal}", value: "family_physician_address_complete", category: "Patient Record" },
    { label: "Referred Physician Last Name", value: "referred_physician_last_name", category: "Patient Record" },
    { label: "Referred Physician First Name", value: "referred_physician_first_name", category: "Patient Record" },
    { label: "Referred Physician Phone", value: "referred_physician_phone", category: "Patient Record" },
    { label: "Referred Physician Fax", value: "referred_physician_fax", category: "Patient Record" },
    { label: "Referred Physician Address", value: "referred_physician_address", category: "Patient Record" },
    { label: "Referred Physician City", value: "referred_physician_city", category: "Patient Record" },
    { label: "Referred Physician Province", value: "referred_physician_province", category: "Patient Record" },
    { label: "Referred Physician Country", value: "referred_physician_country", category: "Patient Record" },
    { label: "Referred Physician Postal", value: "referred_physician_postal", category: "Patient Record" },
    { label: "Referred Physician Address Complete {Address, City, Province Country Postal}", value: "referred_physician_address_complete", category: "Patient Record" },
    { label: "Contact 1 Last Name", value: "contact_1_last_name", category: "Patient Record" },
    { label: "Contact 1 First Name", value: "contact_1_first_name", category: "Patient Record" },
    { label: "Contact 1 Relationship", value: "contact_1_relationship", category: "Patient Record" },
    { label: "Contact 1 Cell Phone", value: "contact_1_cell_phone", category: "Patient Record" },
    { label: "Contact 1 Home Phone", value: "contact_1_home_phone", category: "Patient Record" },
    { label: "Contact 1 Work Phone & Ext", value: "contact_1_work_phone_ext", category: "Patient Record" },
    { label: "Contact 1 Email", value: "contact_1_email", category: "Patient Record" },
    { label: "Contact 2 Last Name", value: "contact_2_last_name", category: "Patient Record" },
    { label: "Contact 2 First Name", value: "contact_2_first_name", category: "Patient Record" },
    { label: "Contact 2 Relationship", value: "contact_2_relationship", category: "Patient Record" },
    { label: "Contact 2 Cell Phone", value: "contact_2_cell_phone", category: "Patient Record" },
    { label: "Contact 2 Home Phone", value: "contact_2_home_phone", category: "Patient Record" },
    { label: "Contact 2 Work Phone & Ext", value: "contact_2_work_phone_ext", category: "Patient Record" },
    { label: "Contact 2 Email", value: "contact_2_email", category: "Patient Record" },
  
    // Clinic Settings
    { label: "Location Name", value: "location_name", category: "Clinic Settings" },
    { label: "Address", value: "address", category: "Clinic Settings" },
    { label: "City", value: "city", category: "Clinic Settings" },
    { label: "Province", value: "province", category: "Clinic Settings" },
    { label: "Postal", value: "postal", category: "Clinic Settings" },
    { label: "Address Complete {Address, City, Province Country Postal}", value: "address_complete", category: "Clinic Settings" },
    { label: "Telephone", value: "telephone", category: "Clinic Settings" },
    { label: "Fax", value: "fax", category: "Clinic Settings" },
  
    // Admin (Physician)
    { label: "CPS ID / CNO ID", value: "cps_cno_id", category: "Admin (Physician)" },
    { label: "Location Indicator", value: "location_indicator", category: "Admin (Physician)" },
    { label: "Visit Location", value: "visit_location", category: "Admin (Physician)" },
    { label: "Specialty", value: "specialty", category: "Admin (Physician)" },
    { label: "First Name", value: "physician_first_name", category: "Admin (Physician)" },
    { label: "Last Name", value: "physician_last_name", category: "Admin (Physician)" },
    { label: "Phone Number", value: "physician_phone_number", category: "Admin (Physician)" },
    { label: "Email", value: "physician_email", category: "Admin (Physician)" },
    { label: "Address", value: "physician_address", category: "Admin (Physician)" },
  
    // General
    { label: "Today's Date (YYYY-MM-DD)", value: "todays_date", category: "General" },
    { label: "Today's Date: Year", value: "todays_date_year", category: "General" },
    { label: "Today's Date: Month", value: "todays_date_month", category: "General" },
    { label: "Today's Date: Day", value: "todays_date_day", category: "General" },
  ];
  