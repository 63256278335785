<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="templateSearch" class="form-control" placeholder="Search Template" v-model="form.keyword" @blur="templateSearch" ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getList"></CustomFilter>
                    </span>
                </div>
                <div>
                    <input type="file" id="fileInput" style="display: none;" @change="save">
                    <button class="comman_btn" @click="selectTemplate" v-if="this.$route.name !== 'patientTemplateList'"> Browse </button>
                </div>
            </div>
            
            <div class="table-responsive consult-list template">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox"></th>
                            <th scope="col" class="active" @click="changeSort('title', $event)" style="width: calc(var(--scale-ratio) * 731px);">Template Description <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('module', $event)" style="width: calc(var(--scale-ratio) * 260px);">Module <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('additional_info', $event)">Notes <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset" v-if="!['consultation', 'imaging', 'lab'].includes(this.form.module)"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in eFormList" :key="index">
                            <td><input class="form-check-input" type="checkbox"></td>
                            <td>
                                <span class="popup_link" @click="selectTemplateRecord(item.id)" v-if="this.$route.name !== 'templateMasterList'">
                                    {{ item.title }}
                                </span>
                                <span class="popup_link" v-else @click="edit(item.id)">{{ item.title }}</span>
                            </td>
                            <td>{{ item.module }}</td>
                            <td>{{ item.additional_info }}</td>
                            <td class="edit_delet_link" v-if="!['consultation', 'imaging', 'lab'].includes(this.form.module)">
                                <span class="popup_link">
									<router-link target="_blank" :to="{ name: 'templateMasterEdit', params: { template_id: item.id } }">Edit</router-link>
								</span>
                                <span class="popup_link" @click="deleteTemplate(item.id)">Delete</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from './sidebar.vue';
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import { helpers, required } from "@vuelidate/validators";
import CustomFilter from '../../base/CustomFilter.vue';
import $ from "jquery";

export default {
    setup() {
		return { v$: useVuelidate() };
	},
    data() {
        return {
            
            isShowCustomFilter: false,
            filterList: [],
            filterOptions:[
                { value: "title", title: "Template Description" },
                { value: "module", title: "Module" },
                { value: "additional_info", title: "Notes" },
            ],
            patient_search: null,
            eFormList: [],
            formSection: false,
            form: {
                file_path : null,
                advanceFilter: [],
                keyword : '',
                sort_by: '',
                sort_order: '',

            }
        }
    },
    components: {
        sidebar,
        CustomFilter,
    },
    methods:{
        changeSort(orderColumnName, event){
            event.stopPropagation();
            const th = event.target;

            this.form.sort_by = orderColumnName;
            if (th.classList.contains('active')) {
                th.classList.remove('active');
                this.form.sort_order = 'asc';
            } else {
                th.classList.add('active');
                this.form.sort_order = 'desc';
            }

            this.getList()
        },
        templateSearch() {
            if(this.form.keyword.length > 2)
            {
                this.getList();
            }
            if(this.form.keyword.length == 0)
            {
                this.getList();
            }
        },
        deleteTemplate(id) {
			const confirmMsg = confirm("Are you sure you want to delete this template?");
			if (confirmMsg) {
				axios.post("e-forms/delete", { 'id': id })
					.then((response) => {
						this.$filters.moshaToast(response.data.message, "success");
						this.getList();
					})
			}
		},
        handleCustomField(values){
            this.filterList = values
            let advanceFilter={}
            values.forEach(item => {
                let value = item.value
                if (item.value == 'Not Set') {
                    value = ''
                }
                advanceFilter[item.field] = value
            });
            this.form.advanceFilter = advanceFilter
        },
        addFilter(){
            this.isShowCustomFilter = !this.isShowCustomFilter;
        },
        selectTemplate(){
            document.getElementById('fileInput').click();
        },
        save() {
            this.$store.state.loader = true;
            this.form.file_path = $("#fileInput").prop("files")[0];
			let url = "e-forms/store";
			const config = {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
					'Content-Type': 'multipart/form-data'
				},
			};
			axios.post(url, this.form, config)
			.then((response) => {
				if (response.status == 200) {
                    this.$store.state.loader = false;
					// this.$filters.moshaToast(response.data.message, "success, ");
                    if (this.$route.name !== 'templateMasterList') {
                        this.$router.push({ name: 'editModuleTemplate', params: { patient_id: this.$route.params.patient_id, template_id:response.data.data.id} });
                    } else {
                        if (response.data.data.file_path && !response.data.data.id) {
                            this.$router.push({
                                name: 'templateMasterEdit',
                                query: { file_path: response.data.data.file_path }
                            });
                        } else if (response.data.data.id) {
                            this.$router.push({
                                name: 'templateMasterEdit',
                                params: { template_id: response.data.data.id }
                            });
                        }
                    }
				} else {
					this.$filters.moshaToast(response.data.message, "error");
				}
			}).catch(error => {
				if (error.response.status === 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			});
		},
        handleFileUpload(event) {
            this.form.file_path = event.target.files[0];
        },
        handleItemSelected(obj){
            this.form.module = obj.value;
        },
        getList(){
            this.$store.state.loader = true;
            if (this.$route.name !== 'templateMasterList') {
                this.form.module = this.$route.params.module;
            }
            axios.post("e-forms/list", this.form )
            .then((response) => {
                this.eFormList = response.data.data;
                this.$store.state.loader = false;
            }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        patientSearch() {
			if (this.patient_search && this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
                .then((response) => {
                    this.patientList = response.data.data;
                })
			}

			if(!this.patient_search || this.patient_search.length == 0){
				this.patientList = [];
				this.isShowCreateDemographics = true;
			}
		},
        selectTemplateRecord(id){
            var type = this.$route.params.module;
            this.$router.push(
                `/patient/e-chart/${this.$route.params.patient_id}/${type}/template/edit/${id}`
            );            
        },
        edit(id){
            if (this.$route.name !== 'templateMasterList') {
                this.$router.push({ name: 'editModuleTemplate', params: { patient_id: this.$route.params.patient_id, template_id:id} });
            } else {
                this.$router.push({ name: 'templateMasterEdit', params: { template_id:id} });
            }

        },
        handleClickOutside(event) {
            if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                this.getList();
                this.isShowCustomFilter = false;
            }

        },
    },
    mounted(){
        document.addEventListener('click', this.handleClickOutside);
        this.getList();
    },
    computed: {
    },
    validations() {
        return {
			form: {
				title: {
					required: helpers.withMessage("Please enter document title.", required),
				},
				file_path: {
					required: helpers.withMessage("Please select file to upload.", required),
				},
			},
		};
    }
}
</script>
