<template>
    <SubPageHeader :headerTitle="getHeaderTitle" v-if="this.$store.state.component != 'templateMasterEdit'"></SubPageHeader>
	<div class="content">
		<div class="container-fluid">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>
<script>
import SubPageHeader from './SubPageHeader.vue'
import templateMasterList from '../components/patient/echart/template.vue';
import templateMasterEdit from '../components/patient/echart/templateEdit.vue';

export default {
	methods: {},
    components: {
        SubPageHeader,
        templateMasterList,
        templateMasterEdit
    },
    created() {
		this.$store.state.component = this.$route.name;
	},
    computed: {
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
	},
}
</script>